.tileContainer {
  display: flex;
  height: 100vh;
  overflow: hidden;
  flex-wrap: wrap;
}

.tileRow {
  display: flex;
  flex-wrap: nowrap;
  margin: 4px 0;
  transform: translateX(-100px);
}

.tile {
  aspect-ratio: 1;
  border-radius: 4px;
  height: 150px;
  width: 150px;
  margin: 4px;
  background-color: #ddd;
  border: 1px solid #aaa;
}
